.react-slider-program {
  height: 40%;
  margin-right: 1em;
  border-radius: 0.375rem;
  border: 1px solid #ced4da;
  min-height: 25px;
}

.react-slider-program-thumb {
  height: 100%;
  border-radius: 0.375rem;
  padding: 0 5px;
  background-color: rgb(13 110 253);
  color: white;
}

.react-slider-program-track {
  height: 100%;
  border-radius: 0.375rem;
}

.react-slider-program-track-0 {
  background-color: white;
}

.react-slider-program-track-1 {
  background: var(--bs-primary);
}

.react-slider-program-track-2 {
  background-color: white;
}

@import "~bootstrap/scss/bootstrap";

@import "custom";
